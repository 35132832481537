.login {
    width: 100%;
    height: 100vh;
    display: flex;
    background-image: url('https://images.pexels.com/photos/1037995/pexels-photo-1037995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
}
form.loginform {
    margin: auto;
    width: 25%;
    height: 35rem;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: center;
    border-radius: 20px;
    background-color: white;
    color: coral;
    
}
h1{
    display: flex;
    justify-content: center;
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}